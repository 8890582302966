import React, { useState } from "react"
import PageTitle from "../components/global/page-title"

import Layout from "../components/layout"
import Seo from "../components/seo"

const content = `<p>3MDR provides an independent community voice for the Mountain District area, achieved with interactive local news, culture, entertainment and emergency alerts.  </p>

<p>3MDR 97.1fm is a community radio station based east of Melbourne, in the forest covered mountains of the Dandenongs. We have recently moved to the beautiful Forest Park Homestead in Upwey South, from which we broadcast to the communities of Ferntree Gully, Upwey, Monbulk, Silvan, Gembrook, Pakenham, Beaconsfield, Belgrave, Emerald, Cockatoo and everywhere in between. The signal can be picked up much further away and can be web streamed online anywhere in the world (with the necessary implements, computer, internet etc). </p>

<h3>Something for Everyone!</h3>

<p>Our presenters are passionate about broadcasting and are actively involved in the community. There is a program for all tastes and interests.</p>

<p>3MDR broadcasts 24 hours a day 7 days a week. Our programming includes music, live performances, news and lifestyle, talk-back and local community arts and culture.</p>

<p>We have specialist music programs that play everything from metal, blues, country, folk and hip-hop. MDR has a high Australian music content and supports independent artists.</p>

<p>MDR proudly hosts a wide variety of ethnic programs, including Greek, Romanian, Sri Lankan, Tamil, Hindi, Croatian, Italian and Samoan.</p>

<p>MDR is an emergency broadcaster and provides vital bushfire information and potentially life-saving updates on Code Red fire days.</p>

<p>3MDR runs free Community Service Announcements for community groups based in the Mountain District License area. You will hear plenty of these, keeping you in touch with local news.</p>

<h3>3MDR is the voice of the community - for the community!</h3>

<p>3MDR is a member of CBAA and CBAV.</p>
 
<p>Community Broadcasting Association Australia<br />
Community Broadcasting Association Victoria</p>
 
 
<p>Station Manager: Phil Ruck<br />
Engagement Co-ordinator: Ren Cuttriss-Garry<br />
Program Manager: Bret Townsend<br />
Music Librarian: Tony Bates<br />
Head Technician: David Miller</p>

<h3>3MDR Committee of Management</h3>
<p>President: Charitha Dissanayake<br />
Vice President: Cath Dear<br />
Secretary: Rachel Nendick<br />
Treasurer: Barbara Baxter<br />
Ordinary Members: Tony Blake, Tim Lines, Chris Riseley, Patrick McCarthy, Sue Amico</p>`

const ContactUs = ({ data, location }) => {
  const siteTitle = `${process.env.GATSBY_SITE_NAME}`
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About Us" />
      <div className="w-full  bg-white rounded-xl shadow-lg p-4 lg p-8">
        <PageTitle title="About Us" />
        <div>
          <section
            className={"article-content"}
            dangerouslySetInnerHTML={{ __html: content}}
            itemProp="articleBody"
          />
        </div>
      </div>
    </Layout>
  )
}

export default ContactUs
